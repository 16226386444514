import React from "react"

import Layout from "../../components/layout"
import QuickForm from "../../components/QuickForm/index"
import { frCH } from "../../lang/fr-CH"

export default function QuickFormPage() {

  return (
    <Layout location="CH" language="fr-CH">
      <QuickForm txt={frCH} language="fr-CH"/>
    </Layout>
  )
}